import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Create,
  Edit,
  SimpleForm,
  TextField,
  TextInput,
  ShowButton,
  EditButton,
  DeleteButton,
  NumberInput,
  required,
} from "react-admin";
import Typography from '@material-ui/core/Typography';

export const CompaniesList = (props: any) => (
  <List {...props}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="address" />
      <TextField source = "code" />
      <TextField source = "secret" />
      <TextField label = "Latitude" source="coordinates.lat" type="number" />
      <TextField label = "Longitude" source="coordinates.lng" type="number" />
      <ShowButton label="" />
      <EditButton label="" />
      <DeleteButton label="" redirect={false} />
    </Datagrid>
  </List>
);

const Aside = () => (
    <div style={{ width: 200, margin: '1em' }}>
        <Typography variant="h6">NOTE!</Typography>
        <Typography variant="body2">
            <strong>All</strong> fields need to be filled for the item to show up on the app. 
            <br/>            
            <br/>
            <strong>Secret</strong> is the code that users without location enabled need to enter to be able to order to this specific company
        </Typography>
    </div>
);

export const CompaniesCreate = (props: any) => (
  <Create aside={<Aside/>} {...props}>
    <SimpleForm>
      <TextInput source="name" validate={[required()]}/>
      <TextInput source="address" validate={[required()]}/>
      <TextInput source = "code" validate={[required()]}/>
      <TextInput source = "secret" validate={[required()]}/>
      <NumberInput label = "Latitude" source="coordinates.lat" type="number"validate={[required()]}/>
      <NumberInput label = "Longitude" source="coordinates.lng" type="number"validate={[required()]}/>
    </SimpleForm>
  </Create>
);

export const CompaniesEdit = (props: any) => (
  <Edit {...props} aside={<Aside/>}>
    <SimpleForm>
      <TextInput source="name" validate={[required()]}/>
      <TextInput source="address" validate={[required()]}/>
      <TextInput source = "code" validate={[required()]}/>
      <TextInput source = "secret" validate={[required()]}/>
      <NumberInput label = "Latitude" source="coordinates.lat" type="number" validate={[required()]}/>
      <NumberInput label = "Longitude" source= "coordinates.lng" type="number" validate={[required()]}/>
    </SimpleForm>
  </Edit>
);
