import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  Create,
  Edit,
  ImageField,
  SimpleShowLayout,
  SimpleForm,
  TextField,
  TextInput,
  ShowButton,
  EditButton,
  DeleteButton,
  FileInput,
  FileField,
  RadioButtonGroupInput,
  NumberInput,
  NumberField,
  required
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import RichTextInput from 'ra-input-rich-text';

const logoImage = makeStyles((theme) => ({
  image: {
    width: 120,
    height: 40,
  },
}));

const productImage = makeStyles((theme) => ({
  image: {
    width: 150,
    height: 200,
  },
}));

export const ProductsList = (props: any) => {
  const logoClass = logoImage();
  const productClass = productImage();

  return (
    <List {...props } sort = {{field: 'appPosition', order: 'ASC'}}>
      <Datagrid>
        <ImageField
          classes={logoClass}
          source="brandImage.src"
          label="Brand Image"
        />
        <ImageField
          classes={productClass}
          source="images.0.src"
          label="First image"
        />
        <TextField source="name" />
        <TextField source="code" />
        <TextField source="weight" />
        <TextField source="brand" />
        <NumberField source="price" />
        <TextField source="store" />
        <NumberField source="appPosition" label="App Position" />
        <TextField source="status" label="Status" />
        <ShowButton label="" />
        <EditButton label="" />
        <DeleteButton label="" redirect={false} />
      </Datagrid>
    </List>
  );
};

export const ProductsShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="weight" />
      <NumberField source="price" />
      <TextField source="description" />
      <FileField
        source="files_multiple.src"
        title="files_multiple.title"
        multiple
      />
    </SimpleShowLayout>
  </Show>
);

export const ProductsCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={[required()]}/>
      <TextInput source="code" validate={[required()]}/>
      <TextInput source="weight" validate={[required()]}/>
      <TextInput source="brand" validate={[required()]}/>
      <NumberInput source="price" validate={[required()]}/>
      <TextInput source="store" validate={[required()]}/>
      <NumberInput source="appPosition" label="App Position" validate={[required()]}/>
      <RichTextInput label="Description" source="description" validate={[required()]}/>
      <FileInput source="images" multiple label="Images" validate={[required()]}>
        <FileField source="src" title="title" />
      </FileInput>
      <FileInput source="brandImage" label="brandImage" validate={[required()]}>
        <FileField source="src" title="title" />
      </FileInput>

      <RadioButtonGroupInput
        id="radio"
        source="status"
        choices={[
          { id: "draft", name: "Draft" },
          { id: "published", name: "Published" },
        ]}
      />
    </SimpleForm>
  </Create>
);

export const ProductsEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" validate={[required()]}/>
      <TextInput source="code" validate={[required()]}/>
      <TextInput source="weight" validate={[required()]}/>
      <TextInput source="brand" validate={[required()]}/>
      <NumberInput source="price" validate={[required()]}/>
      <TextInput source="store" validate={[required()]}/>
      <NumberInput source="appPosition" validate={[required()]}/>
      <RichTextInput label="Description" source="description" validate={[required()]}/>
      <FileInput source="images" multiple label="Images">
        <FileField source="src" title="title" validate={[required()]}/>
      </FileInput>
      <FileInput source="brandImage" label="brandImage">
        <FileField source="src" title="title" validate={[required()]}/>
      </FileInput>

      <RadioButtonGroupInput
        id="radio"
        source="status"
        choices={[
          { id: "draft", name: "Draft" },
          { id: "published", name: "Published" },
        ]}
      />
    </SimpleForm>
  </Edit>
);
