import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  Edit,
  SimpleShowLayout,
  SimpleForm,
  TextField,
  TextInput,
  ShowButton,
  EditButton,
  DeleteButton,
  ReferenceManyField,
  SingleFieldList,
  ChipField
} from "react-admin";

export const UserList = (props: any) => (
  <List {...props} sort = {{field: 'name', order: 'DESC'}}>
    <Datagrid>
      <TextField source = "id"/>
      <TextField source="customer_id" />
      <TextField source="name" />
      <ShowButton label="" />
      <EditButton label="" />
      <DeleteButton label="" redirect={false} />
    </Datagrid>
  </List>
);

export const UserEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="customer_id" />
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
);

export const UserShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="customer_id" />
      <TextField source="name" />
      <TextField source="age" />
      <ReferenceManyField label = "Orders placed by" reference="luxhurray_orders" target="customerUid">
        <SingleFieldList>
        <ChipField source = "price"/>
        </SingleFieldList>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);
