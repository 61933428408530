import React from "react";
import "./App.css";
import { Admin, Resource } from "react-admin";
import { PostList, PostEdit, PostShow } from "./admin-src/orders";
import { UserList, UserEdit, UserShow } from "./admin-src/users";
import {
  ProductsList,
  ProductsEdit,
  ProductsCreate,
} from "./admin-src/products";
import {
  CompaniesList,
  CompaniesCreate,
  CompaniesEdit,
} from "./admin-src/companies";

import {
  FirebaseDataProvider,
  FirebaseAuthProvider,
} from "react-admin-firebase";
import firebase from "firebase";
import UserIcon from "@material-ui/icons/People";
import BusinessIcon from "@material-ui/icons/Business";
import ShopIcon from "@material-ui/icons/Shop";

import { firebaseConfig } from "./FIREBASE_CONFIG";
import CustomLoginPage from "./CustomLoginPage";
import { createMuiTheme } from "@material-ui/core/styles";

const firebaseApp = firebase.initializeApp(firebaseConfig);

const options = {
  logging: true,
  app: firebaseApp,
  persistence: "local" as const,
  dontAddIdFieldToDoc: true,
};

const authProvider = FirebaseAuthProvider(firebaseConfig, options);
const dataProvider = FirebaseDataProvider(firebaseConfig, options);

const darkAppBar = createMuiTheme({
  palette: {
    primary: {
      light: "#ffffff",
      dark: "#000000",
      main: "#000000",
    },
    secondary: {
      light: "#ffffff",
      dark: "#000000",
      main: "#000000",
    },
  },
});

class App extends React.Component {
  render() {
    return [
      <Admin
        theme={darkAppBar}
        loginPage={CustomLoginPage}
        dataProvider={dataProvider}
        authProvider={authProvider}
      >
        <Resource
          options={{ label: "Orders" }}
          name="luxhurray_orders"
          list={PostList}
          show = {PostShow}
          edit={PostEdit}
        />

        <Resource
          options={{ label: "Customers" }}
          name="stripe_customers"
          icon={UserIcon}
          list={UserList}
          show={UserShow}
          edit={UserEdit}
        />

        <Resource
          options={{ label: "Products" }}
          name="products"
          list={ProductsList}
          edit={ProductsEdit}
          create={ProductsCreate}
          icon={ShopIcon}
        />

        <Resource
          name="companies"
          list={CompaniesList}
          create={CompaniesCreate}
          edit={CompaniesEdit}
          icon={BusinessIcon}
        />
      </Admin>,
    ];
  }
}
export default App;
