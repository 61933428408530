import * as React from "react";
import {
  Datagrid,
  List,
  Edit,
  Filter,
  DateField,
  SimpleForm,
  TextField,
  TextInput,
  EditButton,
  RadioButtonGroupInput,
  SimpleShowLayout,
  ShowButton,
  Show

} from "react-admin";

const PostFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search customer" source="recipient" alwaysOn />
    <TextInput label="Confirmation number" source="confirmationNumber" defaultValue="JC"/>
    <TextInput label="Company" source="company.name" />
  </Filter>
);

export const PostList = (props: any) => (
  <List {...props} filters={<PostFilter />} sort = {{field: 'orderDate', order: 'DESC'}}>
    <Datagrid>
      <TextField label = "Confirmation Number" source="confirmationNumber" />
      <TextField label="Customer" source="recipient" />
      <TextField label="Product" source="product.name" />
      <TextField label="Price" source="price" />
      <TextField label="Currency" source="currency" />
      <TextField label="Delivery Address " source="company.address" />
      <TextField label="Company " source="company.name" />
      <TextField label="Receiver " source="receiver" />
      <TextField label="Status " source="orderStatus" />
      <DateField label="Created" source="orderDate" showTime />
      <EditButton label="" />
      <ShowButton label ="" />
    </Datagrid>
  </List>
);

export const PostShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField label = "Confirmation Number" source="confirmationNumber" />
      <TextField label = "Payment Status" source = "paymentStatus" />
      <TextField label = "Payment Status" source = "orderStatus" />
      <TextField label="Customer" source="recipient" />
      <TextField label="Receiver " source="receiver" />
      <TextField label="Status " source="orderStatus" />
      <DateField label="Created" source="orderDate" showTime />
      <TextField label="Customer ID" source="customerUid" />
      <TextField label="Stripe Payment ID" source="intentId" />
      <TextField label="Currency" source="currency" />
      <TextField label="Product" source="product.name" />
      <SimpleShowLayout>
        <TextField label="Price" source="product.price" />
        <TextField label="Brand" source="product.brand" />
        <TextField label="Code" source="product.code" />
        <TextField label="Description" source="product.description" />
        <TextField label="Code" source="product.code" />
        <TextField label="Store" source="product.store" />
        <TextField label="Weight" source="product.weight" />
        <TextField label="App position" source="product.appPosition" />

      </SimpleShowLayout>
      <TextField label="Company " source="company.name" />
      <SimpleShowLayout>
              <TextField label="Delivery Address " source="company.address" />
              <TextField label="Latitude " source="company.coordinates.lat" />
              <TextField label="Longitude " source="company.coordinates.lng" />
      </SimpleShowLayout>
      
    </SimpleShowLayout>
  </Show>
);

export const PostEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <RadioButtonGroupInput
        id="radio"
        source="orderStatus"
        choices={[
          { id: "delivered", name: "Delivered" },
          { id: "inProgress", name: "In Progress" },
          { id: "cancelled", name: "Cancelled" },
        ]}
      />
    </SimpleForm>  
  </Edit>
);
