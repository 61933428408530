export var firebaseConfig = {
  apiKey: "AIzaSyApQiCnwtyjxhfFw-Ym6y-_ZC6L5x4nkQg",
  authDomain: "stripe-test-99c4e.firebaseapp.com",
  databaseURL: "https://stripe-test-99c4e.firebaseio.com",
  projectId: "stripe-test-99c4e",
  storageBucket: "stripe-test-99c4e.appspot.com",
  messagingSenderId: "656016384477",
  appId: "1:656016384477:web:5ac268948225d6bbfc9634",
  measurementId: "G-EDRT37E4FR",
};
